<template>
  <div class="row h-100 justify-content-between">
    <div class="d-none d-lg-block col-lg-2 col-xl-2 bg-white">
      <div class="pl-4">
        <Phaseline
            :phases="process.processPhases"
            :processId="process.id"
            :processLogo="getProcessLogo">
        </Phaseline>
      </div>
    </div>
    <div class="col-lg-9 col-xl-9 justify-content-start">
      <div class="d-flex flex-column px-6 mt-5">
        <!--::Process Info -->
        <div class="card mb-3 card-stretch shadow-sm">
          <div class="card-header border-0">
            <div class="row">
              <div class="col-12">
                <h1
                  class="text-dark text-hover-primary  font-weight-bolder"
                >
                  {{$t("PROCESSFAQ.DOMANDERISPOSTE")}}
                </h1>
              </div>
            </div>
          </div>
        </div>

      <!--::Tool mobile-->
      <div class="row my-2">
        <ProcessMenuMobile :process_id="id" :is_process_faq="isFaq" :is_process_guidelines="isGuidelines"></ProcessMenuMobile>
      </div>

        <div class="card mb-3 card-stretch shadow-sm">
          <div class="card-header border-0">
            <div class="row">
              <div class="col-12">
                <h4
                  style="line-height: 1.5em;"
                  class="mt-10 w-lg-75"
                  v-html="process.faq"
                  v-if="process.faq"
                ></h4>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>


    <div
      class="d-none d-lg-block col-lg-1 col-xl-1 justify-content-between  align-items-center"
    >
      <div class="d-flex flex-column mt-5 mr-5">
        <ProcessMenu :process_id="id"
                     :is_process_faq="isFaq"
                     :is_process_guidelines="isGuidelines"
                     :is_process_about="isAbout"
                     :is_process_narration = "isNarration"></ProcessMenu>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import Phaseline from "@/view/layout/common/Phaseline";
import ProcessMenu from "@/view/layout/common/ProcessMenu";
import ProcessMenuMobile from "@/view/layout/common/ProcessMenuMobile";

import { processMixin } from "@/mixins/process";

export default {
  name: "processFaq",
  mixins: [processMixin],
  components: {
    Phaseline,
    ProcessMenu,
    ProcessMenuMobile
  },
  data() {
    return {
      process: [],
      id: this.$route.params.process_id,
      percentage: 0,
      isFaq: null,
      isGuidelines: null,
      isAbout: null,
      isNarration: null
    };
  },
  mounted() {
    // set the tab from previous
    // this.setActivePreviousTab();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("BCPROCESSI.ELENCOPROCESSI"), route: "/process-list" },
      { title: this.$t("BCPROCESSI.PROCESSO")}
    ]);
  },
  async created() {
    this.$isLoading(true);

    this.process = await this.getProcess(this.id);
    this.isFaq = this.isTrue(this.process.faq);
    this.isGuidelines = this.isTrue(this.process.guidelines);
    this.isAbout = this.isTrue(this.process.about);
    this.isNarration = this.isTrue(this.process.narration);

    this.$isLoading(false);
  },
  methods: {
    isTrue(prop) {
      return prop && prop != "";
    },
  }
};
</script>

<style></style>
